import { Suspense, lazy } from 'react';
import { AuthGuard } from './components/auth-guard';
import { GuestGuard } from './components/guest-guard';
import { LoadingScreen } from './components/loading-screen';
import { DashboardLayout } from './containers/dashboard-layout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Not found pages
const NotFound = Loadable(lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound }))));

// Auth pages
const Login = Loadable(lazy(() => import('./containers/login').then((module) => ({ default: module.Login }))));
const PasswordRecovery = Loadable(lazy(() => import('./containers/password-recovery').then((module) => ({ default: module.PasswordRecovery }))));
const PasswordReset = Loadable(lazy(() => import('./containers/password-reset').then((module) => ({ default: module.PasswordReset }))));
const Register = Loadable(lazy(() => import('./containers/register').then((module) => ({ default: module.Register }))));
const VerifyCode = Loadable(lazy(() => import('./containers/verify-code').then((module) => ({ default: module.VerifyCode }))));

// Dashboard pages
const ControlTower = Loadable(lazy(() => import('./containers/control-tower').then((module) => ({ default: module.ControlTower }))));
const DailyOverview = Loadable(lazy(() => import('./containers/daily-overview').then((module) => ({ default: module.DailyOverview }))));

// Optimizations
const Optimizations = Loadable(lazy(() => import('./containers/optimizations').then((module) => ({ default: module.Optimizations }))));
const OptimizationResult = Loadable(lazy(() => import('./containers/optimization-result').then((module) => ({ default: module.OptimizationResult }))));
const PostalCodeRanges = Loadable(lazy(() => import('./containers/postal-code-ranges').then((module) => ({ default: module.PostalCodeRanges }))));
const OptimizerGroups = Loadable(lazy(() => import('./containers/optimizer-groups').then((module) => ({ default: module.OptimizerGroups }))));

// Groups
const Groups = Loadable(lazy(() => import('./containers/groups').then((module) => ({ default: module.Groups }))));
const GroupCreate = Loadable(lazy(() => import('./containers/group-create').then((module) => ({ default: module.GroupCreate }))));
const GroupEdit = Loadable(lazy(() => import('./containers/group-edit').then((module) => ({ default: module.GroupEdit }))));

// Depots
const Depots = Loadable(lazy(() => import('./containers/depots').then((module) => ({ default: module.Depots }))));
const DepotCreate = Loadable(lazy(() => import('./containers/depot-create').then((module) => ({ default: module.DepotCreate }))));
const DepotEdit = Loadable(lazy(() => import('./containers/depot-edit').then((module) => ({ default: module.DepotEdit }))));

// Vehicles
const Vehicles = Loadable(lazy(() => import('./containers/vehicles').then((module) => ({ default: module.Vehicles }))));
const VehiclesOverview = Loadable(lazy(() => import('./containers/vehicles-overview').then((module) => ({ default: module.VehiclesOverview }))));
const VehicleCreate = Loadable(lazy(() => import('./containers/vehicle-create').then((module) => ({ default: module.VehicleCreate }))));
const VehicleEdit = Loadable(lazy(() => import('./containers/vehicle-edit').then((module) => ({ default: module.VehicleEdit }))));

// Utils
const TimeWindows = Loadable(lazy(() => import('./containers/time-windows').then((module) => ({ default: module.TimeWindows }))));
const Utils = Loadable(lazy(() => import('./containers/utils').then((module) => ({ default: module.Utils }))));
const JobEvents = Loadable(lazy(() => import('./containers/job-events').then((module) => ({ default: module.JobEvents }))));
const Geomapper = Loadable(lazy(() => import('./containers/geomapper').then((module) => ({ default: module.Geomapper }))));

// Users
const Users = Loadable(lazy(() => import('./containers/user').then((module) => ({ default: module.Users }))));
const UserEdit = Loadable(lazy(() => import('./containers/user-edit').then((module) => ({ default: module.UserEdit }))));
const UsersCreate = Loadable(lazy(() => import('./containers/user-create').then((module) => ({ default: module.UserCreate }))));

// Postcodes
const Postcodes = Loadable(lazy(() => import('./containers/postcodes').then((module) => ({ default: module.Postcodes }))));
const PostcodeCreate = Loadable(lazy(() => import('./containers/postcode-create').then((module) => ({ default: module.PostcodeCreate }))));
const PostcodeEdit = Loadable(lazy(() => import('./containers/postcode-edit').then((module) => ({ default: module.PostcodeEdit }))));

// Drivers
const Drivers = Loadable(lazy(() => import('./containers/drivers').then((module) => ({ default: module.Drivers }))));

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <ControlTower />
      },
      {
        path: '/overview',
        element: <DailyOverview />
      },
      {
        path: 'optimizations',
        children: [
          {
            path: '/',
            element: <Optimizations />
          },
          {
            path: ':optimizationId',
            element: <OptimizationResult />
          }
        ]
      },
      {
        path: 'optimizer-groups',
        element: <OptimizerGroups />
      },
      {
        path: 'groups',
        children: [
          {
            path: '/',
            element: <Groups />
          },
          {
            path: 'create',
            element: <GroupCreate />
          },
          {
            path: ':groupId',
            element: <GroupEdit />
          }
        ]
      },
      {
        path: 'postal-code-ranges',
        element: <PostalCodeRanges />
      },
      {
        path: 'depots',
        children: [
          {
            path: '/',
            element: <Depots />
          },
          {
            path: 'create',
            element: <DepotCreate />
          },
          {
            path: ':depotId',
            element: <DepotEdit />
          }
        ]
      },
      {
        path: 'vehicles',
        children: [
          {
            path: '/',
            element: <Vehicles />
          },
          {
            path: '/overview',
            element: <VehiclesOverview />
          },
          {
            path: 'create',
            element: <VehicleCreate />
          },
          {
            path: ':vehicleId',
            element: <VehicleEdit />
          }
        ]
      },
      {
        path: 'time-windows',
        element: <TimeWindows />
      },
      {
        path: 'utils',
        element: <Utils />
      },
      {
        path: 'drivers',
        element: <Drivers />
      },
      {
        path: '/job-events',
        element: <JobEvents />
      },
      {
        path: '/geomapper',
        element: <Geomapper />
      },
    ]
  },
  {
    path: 'users',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/',
        element: <Users />
      },
      {
        path: 'create',
        element: <UsersCreate />
      },
      {
        path: ':userId',
        element: <UserEdit />
      }
    ]
  },
  {
    path: 'postcodes',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/',
        element: <Postcodes />
      },
      {
        path: 'create',
        element: <PostcodeCreate />
      },
      {
        path: ':postcodeId',
        element: <PostcodeEdit />
      }
    ]
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'register',
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    )
  },
  {
    path: 'verify-code',
    element: (
      <GuestGuard>
        <VerifyCode />
      </GuestGuard>
    )
  },
  {
    path: 'password-recovery',
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    )
  },
  {
    path: 'password-reset',
    element: (
      <PasswordReset />
    )
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
